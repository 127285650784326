<template>
    <div class="head-section" :class="classImg">
     <div class="container">
      <p class="text-white" >
        <router-link :to="pathFrom" style="color: inherit; text-decoration: none;">
          {{ PathPageFrom }}
        </router-link>
          &nbsp;
          <svg :class="[this.$i18n.locale == 'en' ? 'rotate-svg':'']" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.85742 10.3641L3.44858 6L7.85742 1.63594L6.2047 -7.22428e-08L0.143136 6L6.2047 12L7.85742 10.3641Z" fill="#AFAFAF" />
        </svg>
        &nbsp;
        <a v-if="PathPageCenter" @click="$router.go(-1)" style="color: inherit; text-decoration: none;cursor: pointer;">
          {{ PathPageCenter }}   &nbsp;
        </a>
        <svg :class="[this.$i18n.locale == 'en' ? 'rotate-svg':'']" v-show="PathPageCenter" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.85742 10.3641L3.44858 6L7.85742 1.63594L6.2047 -7.22428e-08L0.143136 6L6.2047 12L7.85742 10.3641Z" fill="#AFAFAF" />
        </svg>
          &nbsp;
        <a :href="pathTo" style="color: #225476; text-decoration: none;">
          {{ PathPageTo }}
        </a>
      </p>
          <img :src="SVG" alt="">
        <h1 class="text-white">{{ TitlePage }}</h1>
        <h2 class="text-white">{{ TitleSec }}</h2>
     </div>
    </div>
  </template>
  <script>
  export default {
     props: {
      classImg: String,
     TitlePage: String,
     TitleSec: String,
     PathPageFrom: String,
     PathPageTo: String,
     SVG:String,
     pathFrom:String,
     pathCenter:String
   },
  };
  </script>

